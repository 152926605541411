// import logo from './logo.svg';
import { Provider } from 'react-redux';
// import store from './redux/store';

import './App.css';
import Home from './Pages/Home';
import Footer from './Shared/Footer'
import Header from './Shared/Header';
import Navbar from './Shared/Navbar';
import About from './Pages/About';
import Blogs from './Pages/Blogs/Blogs';
import SubBlog from './Pages/Blogs/SubBlog'
import Myworks from './Pages/Works/Myworks'
import { BrowserRouter as Router, Routes, Route, Switch } from 'react-router-dom';
import Subwork from './Pages/Works/SubWork';
import NotFound from './Pages/NotFound';
import './css/main.css?test=1920';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';
import Connector from './DB/Connector'
import { pdfjs } from 'react-pdf';
import PostBlog from './Pages/Blogs/PostBlog';
import Auth from './Pages/Auth';

import { ReactReduxFirebaseProvider} from "react-redux-firebase"


// import {ToastContainer} from "react-toastify"
// import {rrfProps} from "./redux/store"


// ---------

import { createStore, applyMiddleware, compose } from 'redux'
import logger from 'redux-logger'
import rootReducer from './redux/reducer/rootReducer'
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk'

import { getFirebase } from 'react-redux-firebase';
import firebase from "./DB/DBConnect"
import {createFirestoreInstance} from "redux-firestore"
import { FaLeaf } from 'react-icons/fa6';
import PostWork from './Pages/Works/PostWork';
import UpdateBlog from './Pages/Blogs/UpdateBlog';
import UpdateWork from './Pages/Works/UpdateWork';
// ---------


const store = createStore(
  rootReducer,
  applyMiddleware(thunk.withExtraArgument({ getFirebase }))
);

const rrfProps = {
  firebase,
  config: {},
  dispatch: store.dispatch,
  useFirestoreForProfile: true, 
  updateProfileOnLogin: false,
  createFirestoreInstance
}

// const rrfInstance = createFirestoreInstance(getFirestore(), rrfProps);

// -- pdf
pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.js',
  import.meta.url,
).toString();

function App() {
  return (
    <Provider store={store}>
      <ReactReduxFirebaseProvider {...rrfProps}>
        {/* <ToastContainer /> */}
      <Header />
      <Navbar />
      {/* <About /> */}
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/home" component={Home} />
        <Route exact path="/works" component={Myworks} />
        <Route exact path="/postblog" component={PostBlog} />
        <Route exact path="/postwork" component={PostWork} />

        <Route exact path="/connector" component={Connector} />

        <Route exact path="/works/view/:id" component={Subwork} />
        <Route exact path="/blogs/view/:id" component={SubBlog} />

        <Route exact path="/blogs/update/:id" component={UpdateBlog} />
        <Route exact path="/works/update/:id" component={UpdateWork} />

        <Route exact path="/blogs" component={Blogs} />


        <Route exact path="/cv" component={About} />
        <Route exact path="/auth" component={Auth} />


        <Route path="*" component={NotFound} />
      </Switch>
      {/* <Blogs /> */}
      <Footer />
      </ReactReduxFirebaseProvider>
    </Provider>
    // <div className="App">
    //   <header className="App-header">
    //     <img src={logo} className="App-logo" alt="logo" />
    //     <p>
    //       Edit <code>src/App.js</code> and save to reload.
    //     </p>
    //     <a
    //       className="App-link"
    //       href="https://reactjs.org"
    //       target="_blank"
    //       rel="noopener noreferrer"
    //     >
    //       Learn React
    //     </a>
    //   </header>
    // </div>
  );
}

export default App;


const authReducer = (state= {}, action) => {

    switch(action.type) {
        
        case "SIGN_IN":{
            console.log("Login successfully");
            return state;
        }

        case "SIGN_IN_ERR":  {
            console.log("Login insuccessfully")
            return state;
        }

        case "SIGN_OUT": {
            return state;
        }

        default: return state;
    }

}


const workReducer = (state = {}, action) => {
    switch(action.type){
        case "ADD_WORK":
            {
                console.log("added work")
                return state
            }
        case "ADD_WORK_ERR":
            {
                console.log("an erro occured with work")
                return state
            }

        case "REMOVE_WORK":
            {
                return state
            }


        case "REMOVE_WORK_ERR":
            {
                console.log("an erro occured with work")
                return state
            }

        case "UPDATE_SUCCESS_WORK":
            {
                return state
            }
        
        case "UPDATE_WORK_ERR":
            {
                console.log("an erro occured with work")
                return state
            }
        
        default: return state
    }
};

export default workReducer;
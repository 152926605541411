import React from 'react';

import {searchByBlog} from '../../redux/action/blogAction';
import { connect } from "react-redux";

import { compose } from "redux";
import { firestoreConnect } from "react-redux-firebase";
import RemoveWork from './removeWork';
import UpdateWorkBtn from './UpdateWorkBtn';
import timeAgo from "../../Shared/CalcMoment";


function FetchWork(props) {
    // console.log(props)
    // const {id} = props.match.params;

    return (
        <div>

            {props.workObj && props.workObj[0] ? <div>
            <h3>Title: {props.workObj && props.workObj[0].title}</h3> 
            <p>Last update: <i>{props.workObj && timeAgo(props.workObj[0].creationDate)}</i></p>
            
            <p dangerouslySetInnerHTML={{__html: props.workObj[0].description}}></p>
            <hr></hr>
            <div className="card" style={{width: '90%', padding: '50px'}}>
                <div dangerouslySetInnerHTML={{__html: props.workObj[0].work}}></div>
                {/* <div className="card-body" style={{textAlign: 'left'}}> */}
                {/* <div dangerouslySetInnerHTML={{__html: props.blogObj[0].blog}}></div> */}

                {/* </div> */}
            <div>

            </div>
                      {/* <h3 style={{color: 'red'}}>الرجاء التآكد من الرابط</h3>  */}
            </div>                 
            
            {props.uid && props.workObj && <RemoveWork workCase={props.workObj[0]}/>}
            {props.uid && props.workObj && <UpdateWorkBtn workCase={props.workObj[0]}/>}</div> : <p>Page not found</p>}

   

        </div>
    );
}


const mapDispatchToProps = state => {
    
    // console.log(state);
    const workObj = state.firestore.ordered.works;
    console.log(workObj)
    return {
        workObj: workObj,
        uid: state.firebase.auth.uid
    };

}




export default compose(
    connect(mapDispatchToProps),
    firestoreConnect((ownProps) => 
    [
     {
        collection: "works",
        where: ["unique_title", "==", ownProps.title],
        // orderBy: ["date", "desc"],
      },
    ])

  ) (FetchWork);
import React, { Component } from 'react';
import PostInput from '../../Shared/PostInput';
import {connect} from "react-redux"
// ES6
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useParams
  } from "react-router-dom";
  
// CommonJS

const UpdateWorkBtn  = (props) => {

  
    
    return (
        props.workCase ? <Link  
        to={
          {
            pathname: `/works/update/${props.workCase.title}`,
            state: {oldWork: props.workCase}
          }
        }
        >
            <button>Update the work</button>
        </Link> : <p>Loading</p>
    )


}




export default UpdateWorkBtn;

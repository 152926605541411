import { combineReducers } from "redux";

import {firebaseReducer} from "react-redux-firebase"
import {firestoreReducer} from "redux-firestore"
import blogReducer from "./blogReducer"
import workReducer from './workReducer'
import authReducer from './authReducer'

const rootReducer = combineReducers({
    firebase: firebaseReducer,
    firestore: firestoreReducer,
    blog: blogReducer,
    work: workReducer,
    auth: authReducer
})

export default rootReducer;
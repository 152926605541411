import React, { Component } from 'react';
import PostInput from '../../Shared/PostInput';
import {connect} from "react-redux"
import {addBlog, removeBlog} from '../../redux/action/blogAction'
// ES6
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';

// CommonJS

class RemoveBlog extends Component {


  handleSubmit = (e) => {
    e.preventDefault();
    console.log(this.props.blogCase)

    this.props.removeBlog(this.props.blogCase);
    // console.log(this.state)
    // console.log("Hi")
  }

  render() {
    
    return (
          <form 
              id="addTaskForm"
              className="container"
              autoComplete="off"
              onSubmit={this.handleSubmit}
          >
      
              <button>Delete the post</button>

          </form>
    )
  }

}


const mapDispatch = dispatch => {
  return {
    removeBlog: blog => dispatch(removeBlog(blog))
  }
}

export default connect(null, mapDispatch) (RemoveBlog);


export const signIn = creds => {

    return (dispatch, getState, {getFirebase}) => {

        const firebase = getFirebase();

        firebase
        .auth()
        .signInWithEmailAndPassword(creds.email, creds.password)
        .then(() => {
            console.log("Login successfully")
            dispatch({type: "SIGN_IN"});
        })
        .catch(err => {
            console.log("Wrong successfully")
            dispatch({type: "SIGN_IN_ERR"}, err);
        })
    }
}


export const signOut = () => {

    return (dispatch, getState, {getFirebase} ) => {

        const firebase = getFirebase();

        firebase
            .auth()
            .signOut()
            .then(() => {
                console.log("Signout successfully")
                dispatch({ type: "SIGN_OUT"});
            });
    }
}
import React, { Component } from 'react';
import PostInput from '../../Shared/PostInput';
import {connect} from "react-redux"
import {addBlog} from '../../redux/action/blogAction'
// ES6
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import {updateBlog} from '../../redux/action/blogAction'
// CommonJS

class UpdateBlogPage extends Component {

  state = {

    unique_title: this.props.location.state.oldBlog.unique_title,
    title: this.props.location.state.oldBlog.title,
    blog: this.props.location.state.oldBlog.blog,
    description: this.props.location.state.oldBlog.description,
    category: this.props.location.state.oldBlog.category,
    date: this.props.location.state.oldBlog.date,

  }

  handleValueText = (e) => {
    this.state.blog = e    
  }
  handleValueTextdescription= (e) => {
    this.state.description = e    
  }

  handleChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value,
    })
  }

  handleSubmit = (e) => {
    // console.log("the data you want to update is", this.props.location.state.oldBlog)
    e.preventDefault();
    this.props.updateBlog(this.props.location.state.oldBlog,this.state);
    // console.log(this.state)
    // console.log("Hi")
  }

  render() {
    
    return (
      <div className="content" id ="content">
            <div className="panel-center">
            </div>


                <form 
                    id="addTaskForm"
                    className="container"
                    autoComplete="off"
                    onSubmit={this.handleSubmit}
                >
                <div className="panel-center">
                    
                    <input type="text" placeholder="Unique title" className="inputEnter" id="unique_title" onChange={this.handleChange} defaultValue={this.state.unique_title}/>
                    <input type="text" placeholder="Titile here.." className="inputEnter" id="title" onChange={this.handleChange} defaultValue={this.state.title}/>
                    <input type="number" placeholder="Category 1-6" className="inputEnter" id="category" onChange={this.handleChange} defaultValue={this.state.category}/>

                    <input type="datetime-local" placeholder="Date" className="inputEnter" id="date" onChange={this.handleChange} defaultValue={this.state.date}/>
                   
                    <select id="status" onChange={this.handleChange}>
                      <option value="public">Publish</option>
                      <option value="draft">Draft</option>
                    </select>

                  {/* <textarea placeholder="Description" id="description" onChange={this.handleChange}></textarea> */}
                  <p>Description</p>
                  <PostInput handleValueText={this.handleValueTextdescription} valueText={this.state.description}/>
                  <hr></hr>
                  <p>Content</p>
                  <PostInput handleValueText={this.handleValueText} valueText={this.state.blog}/>

                  {/* <ReactQuill
                      onChange={this.handleValueText} 
                      // onChange={this.previewCode}
                      theme="snow"                      
                  /> */}

                </div>
                  <div className="panel-left">
                    <button>Update the post</button>
                  </div>
                </form>
               
             
              </div>

    )
  }

}


const mapDispatch = dispatch => {
  return {
    updateBlog: (blog, newBlog) => dispatch(updateBlog(blog, newBlog))
  }
}

export default connect(null, mapDispatch) (UpdateBlogPage);

import React, {useState, useEffect} from 'react';
import CountUp from 'react-countup';
import { AiFillGithub, AiFillLinkedin, AiFillTwitterSquare, AiFillYoutube } from 'react-icons/ai';
import axios from 'axios';
import Experience from './Experience';
var jp = require('jsonpath');

const SoicalPanel = ({params}) => {


  return (
    <div className="panel-left">
      
            {/* <div className="card"> */}
                {/* <div className="card-header"> */}
                  {/* <p className="ar">Statistics</p> */}
                  {/* <p className="en">احصائيات موقعي</p> */}
                {/* </div> */}
                {/* <div className="card-body"> */}
                    {/* <h3>Total works: <CountUp delay={1} end={works}    duration={10}/> */}

                    {/* </h3> */}
                    {/* <h2>Total files</h2> */}
                    {/* <h3>Total blogs: [Coming soon...]</h3> */}
                {/* </div> */}
              {/* </div> */}

              <Experience />

              <div className="card">
                <div className="card-header">
                  <p className="ar">Social Media</p>
                  <p className="en">تواصل الاجتماعي</p>
                </div>
                <div className="card-body">
                    <p><AiFillLinkedin /> <a href="https://www.linkedin.com/in/ibrahiml" target="_blank">Linkedlin</a></p>
                    <p><AiFillGithub /> <a href="https://github.com/ibrahim1net" target="_blank">Github</a></p>
                    <p><AiFillTwitterSquare /> <a href="https://twitter.com/ibrahim1net" target="_blank">Twitter</a></p>
                    <p><AiFillYoutube /> <a href="https://www.youtube.com/@DevIbrahim" target="_blank">Youtube</a></p>
                </div>
              </div>
    </div>

);
} 

export default SoicalPanel;

import React, {useState, useEffect} from 'react';
import SoicalPanel from '../../Shared/SoicalPanel';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useParams
  } from "react-router-dom";


import { connect } from "react-redux";
import { compose } from "redux";
import { firestoreConnect } from "react-redux-firebase";


const Myworks = ({worksM, uid}) => {

   
    return (
    
        <div className="content" id ="content">
                <div className="panel-center">
                  <form>
                    <input type="text" placeholder="Search for a project" name="search" id="fsrch" />
                  </form>
                  {uid && <Link to="/postwork">
                    <button style={{width: '50%'}}>Add New work <i class="fa fa-eye" aria-hidden="true"></i> </button>
                  </Link>}
                  

                  {/* <img src="" /> */}
                <center>
                  {/* {loading && <div class="lds-ripple"><div></div><div></div></div>} */}
                  </center>
                  
                  {/* <img src={require('../img/work1061/work1061_1.jpeg')} alt="k"/> */}
                    {
                        worksM && worksM.map((m, index) => 
                            <>
                                <hr></hr>
                                
                                    <div className="card" key={m.id}>
                                        <div className="card-header">
                                            <p className="ar">[ #{index+1} - {m.title} ]</p>
                                        </div>
                                        <div className="card-body" >
                                            <h3>{m.title}</h3>
                                          
                                            <div dangerouslySetInnerHTML ={{__html: m.description}} ></div>
                                            {/* <div dangerouslySetInnerHTML ={{__html: m.work}} ></div> */}

                                            <div>{m.content}</div>
                                            <Link to={`/works/view/${m.unique_title}`}><button style={{width: '50%'}}>More details <i class="fa fa-eye" aria-hidden="true"></i> </button></Link>

                                            <br />
                                            <small style={{color: 'gray'}}>{m.date}</small>
                                            {/* <h3>جاري العمل عليه</h3> */}
                                        </div>
                                    </div>
                                 <hr></hr>
                            </>
                        )
                    }
                    <center>
                      {!worksM && <div class="lds-ripple"><div></div><div></div></div>}
                    </center>
                  </div>
                <div className="panel-right">
        
                </div>
                <SoicalPanel/>
              </div>
        );
}

// export default Myworks;

// export default Blogs;
const mapStateToProps = (state) => {
  // console.log(state);
  const worksM = state.firestore.ordered.works;
  return {
    worksM: worksM,
    uid: state.firebase.auth.uid
  };
};
export default compose(
  connect(mapStateToProps),
  firestoreConnect((ownProps) => {

    const titleMatch = ownProps.location.search.match(/search=(.*)/);
    const title = titleMatch ? titleMatch[1] : null;


    if (title) {
      return [
        {
          collection: "works",
          where: [
            ["title", ">=", title],  // Start matching from `title`
            ["title", "<", title + '\uf8ff'] // Ensures it matches all titles starting with `title`
          ],
          // orderBy: ["date", "desc"],
        },
      ];
    }

    return [
      {
        collection: "works",
        // orderBy: ["date", "desc"],
      },
    ];   
  }
 )
)(Myworks);
import React, { Component } from 'react';
import {signIn} from "../redux/action/authAction"
import {connect} from "react-redux"
import {Redirect} from "react-router-dom"

class Auth extends Component {
    state = {
        email: "",
        password: ""
    }


    handleValueEmail = (e) => {
        this.setState({
            [e.target.id]: e.target.value,
        });
    }


    handleSubmit = (e) => {
        console.log(this.state)
        e.preventDefault();
        this.props.signIn(this.state);
        // console.log(this.state)
        // console.log("Hi")
      }

    render() {
        const {uid} = this.props;
        if(uid) return <Redirect to="/" />

        return (    
            <div>
                <div className="panel-center">


                <form 
                    id="addTaskForm"
                    className="container"
                    autoComplete="off"
                    onSubmit={this.handleSubmit}
                >
                    <h2>Login admin ibrahim1</h2>
                    <input type="text" placeholder="email" id="email"  onChange={this.handleValueEmail}/>
                    <input type="password" placeholder="password"  id="password" onChange={this.handleValueEmail}/>
                    <button>Login</button>
                </form>

                </div>
            </div>
        )
    }

}


const mapStateToProps = (state) => {
    const uid = state.firebase.auth.uid;
    console.log(uid);


    return {
        uid: uid
    }
}

const mapDispatchToProps = dispatch => {
    
    // const uid = state.firebase.auth.uid;

    return {
        signIn: creds => dispatch(signIn(creds))
    }
  }
  
  export default connect(mapStateToProps, mapDispatchToProps) (Auth);

import React, { Component } from 'react';
import PostInput from '../../Shared/PostInput';
import {connect} from "react-redux"
import {removeWork} from '../../redux/action/worksAction'
// ES6
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';

// CommonJS

class RemoveWork extends Component {


  handleSubmit = (e) => {
    e.preventDefault();
    console.log(this.props.workCase)

    this.props.removeWork(this.props.workCase);
    // console.log(this.state)
    // console.log("Hi")
  }

  render() {
    
    return (
          <form 
              id="addTaskForm"
              className="container"
              autoComplete="off"
              onSubmit={this.handleSubmit}
          >
      
              <button>Delete the work</button>

          </form>
    )
  }

}


const mapDispatch = dispatch => {
  return {
    removeWork: work => dispatch(removeWork(work))
  }
}

export default connect(null, mapDispatch) (RemoveWork);

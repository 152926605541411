import React, { Component } from 'react';
import PostInput from '../../Shared/PostInput';
import {connect} from "react-redux"
import {addBlog} from '../../redux/action/blogAction'
// ES6
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useParams
  } from "react-router-dom";
  
// CommonJS

const UpdateBlogBtn  = (props) => {

  
    
    return (
        <Link  
        to={
          {
            pathname: `/blogs/update/${props.blogCase.title}`,
            state: {oldBlog: props.blogCase}
          }
        }
        >
            <button>Update the post</button>
        </Link>
    )


}




export default UpdateBlogBtn;

export const addWorks = (work) => {
    return (dispatch, getState, {getFirebase} ) => {
        // const firebase = getFirebase();
        // const firestore = firebase.firestore();
        console.log("the action is ruuning")
        const firestore = getFirebase().firestore();

        const authorId = getState().firebase.auth.uid;

        firestore
        .collection("works")
        .add({
            ...work,
            // authorId: authorId,
            creationDate: new Date().toString()
        })
        .then(() => {
            console.log("added successfully")

            dispatch({
                type: "ADD_WORK",
                work
            });

        }).catch(err => {
            console.log("something went wrong", err)
            dispatch({
                type: "ADD_WORK_ERR",
                err
            });

        });
    }
}

//remove work 

export const removeWork = work => {
    console.log("something being triggered from blogAction: ", work.id)
    return (dispatch, getState, {getFirebase}) => {
        const firestore = getFirebase().firestore();
        const authorId = getState().firebase.auth.uid;
        console.log("the action is ruuning")

        firestore
            .collection("works")
            .doc(work.id)
            .delete()
            .then(() => {
                console.log("deleted successfuly")
                dispatch({
                    type: "REMOVE_WORK"
                });
            })
            .catch(err => {
                console.log("notdeleted successfuly")

                dispatch({
                    type: "REMOVE_WORK_ERR",
                    err
                });
            });
    }
}

// update work 
export const updateWork = (work, newWork) => {
    console.log("something updated being triggered from work Action: ", work.id)
    return (dispatch, getState, {getFirebase}) => {
        const firestore = getFirebase().firestore();
        const authorId = getState().firebase.auth.uid;
        console.log("the action is ruuning")

        firestore
            .collection("works")
            .doc(work.id)
            .update(newWork)
            .then(() => {
                console.log("updated successfuly")
                dispatch({
                    type: "UPDATE_SUCCESS_WORK"
                });
            })
            .catch(err => {
                console.log("updated fail")
                dispatch({
                    type: "UPDATE_WORK_ERR",
                    err
                });
            });
    }
}
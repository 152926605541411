import React, {useState} from 'react';
import SearchBar from '../Components/SearchBar';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams
} from "react-router-dom";


import { connect } from "react-redux";
import { compose } from "redux";
import { firestoreConnect } from "react-redux-firebase";
import { GiRayGun } from 'react-icons/gi';

import {removeBlog} from "../../redux/action/blogAction"

import timeAgo from "../../Shared/CalcMoment";



const Blogs = (props) => {
  
  function showCategory(c) {
    switch(c){
      case 1:
        return "Programming 💻"
      case 2:
        return "Cybersecurity 🔒"
      case 3:
        return "Technology 🧑🏽‍💻"
      case 4:
        return "Project management 💼"
      case 5:
        return "Emotional Intelligence 🧠"
      case 6:
        return "other 😬"

    }
  }
  
  // console.log("The search query: ", props.location.search.match(/search=(.*)/)[1])
 
  const blogsM = props.blogsM

  const [searchTerm, setSearchTerm] = useState('');

   // Function to format the date
   const formatDate = (date) => {
     console.log(date)
     const options = { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', hour12: true };
     const parts = date.toLocaleString('en-US', options).split(', ');
     const datePart = parts[0];
     const timePart = parts[1];
     return `${datePart} ${timePart}`;
  };

  
  return (
      <div className="content" id ="content">

         <div className="panel-left">
              <div className="side-nav">
                <ul className="sub-side">
     
                    <h3>What I talk about ?</h3>
                    <div>
                      <button>Programming 💻</button>
                      <button>Cybersecurity 🔒</button>
                      <button>Technology 🧑🏽‍💻</button>
                      <button>Project management 💼</button>
                      <button>Emotional Intelligence 🧠</button>
                      <button>other 😬</button>
                  </div>

                </ul>

                
                </div>
            </div>
            
            <div className="panel-center">
              <h2>Total blogs: {blogsM && blogsM.length}</h2>
                <search>
                  <form>
                    {/* <SearchBar placeholder="Search for a blog"/> */}
                    
                    <input type="text" placeholder="Search for a blog" name="search" id="fsrch" value={searchTerm} onChange={(e)=>setSearchTerm(e.target.value)} />
                  </form>
                </search>
                {props.uid && <Link to="/postblog"><button style={{width: '50%'}}><i class="fa fa-eye" aria-hidden="true"></i> Add a new blog+</button></Link>}
                
                {/* <div style={{padding: '10px'}}>
                  <label style={{background: 'gray', padding: '5px', textAlign: 'center', fontSize: '12px', fontWeight: 'bold', borderRadius: '10px'}}>Project Management</label>
                  <label style={{background: 'gray', padding: '5px', textAlign: 'center', fontSize: '12px', fontWeight: 'bold', borderRadius: '10px'}}>Programming</label>
                </div> */}


                <div className="card">
                  <div className="card-header">
                    <p className="ar">[ IBRAHIM blogs ]</p>
                  </div>

                  <center>
                    {!blogsM && <div class="lds-ripple"><div></div><div></div></div>}
                  </center>  
                                  
                  <div className="card-body" style={{textAlign: 'left'}}>
                    
                    <main>
                      
                      {
                        blogsM && blogsM.map(m => 
                          <article key={m.id}>

                            <h2>{m.title} : <label style={{background: 'gray', padding: '5px', textAlign: 'center', fontSize: '12px', fontWeight: 'bold', borderRadius: '10px'}}>{ showCategory(parseInt(m.category))}</label></h2>

                            <p>
                              <div dangerouslySetInnerHTML={{__html: m.description}}></div>

                              <Link  
                                to={
                                  {
                                    pathname: `/blogs/view/${m.unique_title}`,
                                    state: {blog: m}
                                  }
                                  
                                }
                                >
                                <button style={{width: '50%'}}><i class="fa fa-eye" aria-hidden="true"></i>  View more details</button>
                              </Link>
                           
                              <p>posted in: <i>{timeAgo(m.creationDate)}</i></p>
                              {/* <p>{timeAgo(m.creationDate)}</p> */}
                            </p>
                            <hr></hr>
                            {/* <div dangerouslySetInnerHTML={{__html: m.blog}}></div> */}

                          </article>   
                        )
                      }
                    </main>

                  </div>

                </div>

              </div>

            <div className="panel-right">
                      
            </div>
           
          </div>
  )
}

// export default Blogs;




const mapStateToProps = (state) => {
  // console.log("the world state: ", state);
  const blogsM = state.firestore.ordered.blogs;
  return {
    blogsM: blogsM,
    uid: state.firebase.auth.uid,
  };
};

export default compose(
  connect(mapStateToProps),
  firestoreConnect((ownProps) => {
    const titleMatch = ownProps.location.search.match(/search=(.*)/);
    const title = titleMatch ? titleMatch[1] : null;

    if (title) {
      return [
        {
          collection: "blogs",
          where: [
            ["title", ">=", title],  // Start matching from `title`
            ["title", "<", title + '\uf8ff'] // Ensures it matches all titles starting with `title`
          ],
          // orderBy: ["date", "desc"],
        },
      ];
    }

    return [
      {
        collection: "blogs",
        // orderBy: ["date", "desc"],
      },
    ];
  })
) (Blogs);
import React from 'react';

import {searchByBlog} from '../../redux/action/blogAction';
import { connect } from "react-redux";

import { compose } from "redux";
import { firestoreConnect } from "react-redux-firebase";
import RemoveBlog from './RemoveBlog';
import UpdateBlogBtn from './UpdateBlogBtn';
import timeAgo from "../../Shared/CalcMoment";


function FetchBlog(props) {
    // console.log(props)

    function showCategory(c) {
        switch(c){
          case 1:
            return "Programming 💻"
          case 2:
            return "Cybersecurity 🔒"
          case 3:
            return "Technology 🧑🏽‍💻"
          case 4:
            return "Project management 💼"
          case 5:
            return "Emotional Intelligence 🧠"
          case 6:
            return "Stories 📜"
          case 7:
            return "English 📗"
          case 8:
            return "Arabic 📘"
        }
      }

      function formatHTML(html) {
        let formatted = '';
        const indentChar = '  '; // Indentation character (2 spaces)
        let indentLevel = 0;
  
        // Split HTML into lines by tags
        html.split(/>\s*</).forEach((element) => {
          if (element.match(/^\/\w/)) {
            // Closing tag: decrease indentation
            indentLevel--;
          }
  
          formatted += indentChar.repeat(indentLevel) + '<' + element.trim() + '>\n';
  
          if (element.match(/^<?\w[^>]*[^/]$/) && !element.startsWith('!')) {
            // Opening tag: increase indentation
            indentLevel++;
          }
        });
  
        return formatted.trim();
      }

    return (
        <div>
            {props.blogObj && props.blogObj[0] ? <div>
            <h3>Title Blog: {props.blogObj && props.blogObj[0].title}</h3> 
            <p>Last update: <i>{props.blogObj && timeAgo(props.blogObj[0].creationDate)}</i></p>
            {props.blogObj && <label style={{background: 'gray', padding: '5px', textAlign: 'center', fontSize: '12px', fontWeight: 'bold', borderRadius: '10px'}}>{ showCategory(parseInt(props.blogObj[0].category))}</label>}
            
            <p dangerouslySetInnerHTML={{__html: props.blogObj[0].description}}></p>
           
            <hr></hr>

            <div  className="card" style={{padding: '20px'}}>
            {/*<div>{formatHTML(props.blogObj[0].blog)}</div> */}
              <p 
                
                style={{
                  fontFamily: 'Arial, sans-serif' ,
                  listStylePosition: 'inside'
                }}
              dangerouslySetInnerHTML={{__html: props.blogObj[0].blog}}></p>

            <div>

            </div>
                      {/* <h3 style={{color: 'red'}}>الرجاء التآكد من الرابط</h3>  */}
            </div>                 {props.uid && props.blogObj && <RemoveBlog blogCase={props.blogObj[0]}/>}
                {props.uid && props.blogObj && <UpdateBlogBtn blogCase={props.blogObj[0]}/>}</div> : <p>Page not found</p>}
            
   

        </div>
    );
}


const mapDispatchToProps = state => {
    
    // console.log(state);
    const blogObj = state.firestore.ordered.blogs;
    return {
        blogObj: blogObj,
        uid: state.firebase.auth.uid
    };

}




export default compose(
    connect(mapDispatchToProps),
    firestoreConnect((ownProps) => 
    [
     {
        collection: "blogs",
        where: ["unique_title", "==", ownProps.title],
        // orderBy: ["date", "desc"],
      },
    ])

  ) (FetchBlog);
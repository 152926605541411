

export const addBlog = (blog) => {
    return (dispatch, getState, {getFirebase} ) => {
        // const firebase = getFirebase();
        // const firestore = firebase.firestore();
        console.log("the action is ruuning")
        const firestore = getFirebase().firestore();

        const authorId = getState().firebase.auth.uid;

        firestore
        .collection("blogs")
        .add({
            ...blog,
            // authorId: authorId,
            creationDate: new Date().toString()
        })
        .then(() => {
            console.log("added successfully")

            dispatch({
                type: "ADD_BLOG",
                blog
            });

        }).catch(err => {
            console.log("something went wrong", err)
            dispatch({
                type: "ADD_BLOG_ERR",
                err
            });

        });
    }
}

// search a specific blog

export const searchByBlog = title => {
    console.log("search a blog", title)

    return (dispatch, getState, {getFirebase}) => {
        const firestore = getFirebase().firestore();
        // const authorId = getState().firebase.auth.uid;

        firestore
        .collection("blogs")
        .doc()
        .where("title", "==", "DLP")
        .then(() => {
            console.log("fetch successfuly")
            dispatch({
                type: "FETCH_SPECIFIC_BLOG"
            });
        })
        .catch(err => {
            console.log("fetch is not successfuly")

            dispatch({
                type: "FETCH_SPECIFIC_BLOG_ERR",
                err
            });
        });

        }
}

// remove a blog 

export const removeBlog = blog => {
    console.log("something being triggered from blogAction: ", blog.id)
    return (dispatch, getState, {getFirebase}) => {
        const firestore = getFirebase().firestore();
        const authorId = getState().firebase.auth.uid;
        console.log("the action is ruuning")

        firestore
            .collection("blogs")
            .doc(blog.id)
            .delete()
            .then(() => {
                console.log("deleted successfuly")
                dispatch({
                    type: "REMOVE_BLOG"
                });
            })
            .catch(err => {
                console.log("notdeleted successfuly")

                dispatch({
                    type: "REMOVE_BLOG_ERR",
                    err
                });
            });
    }
}

export const updateBlog = (blog, bewBlog) => {
    console.log("something updated being triggered from blogAction: ", blog.id)
    return (dispatch, getState, {getFirebase}) => {
        const firestore = getFirebase().firestore();
        const authorId = getState().firebase.auth.uid;
        console.log("the action is ruuning")

        firestore
            .collection("blogs")
            .doc(blog.id)
            .update(bewBlog)
            .then(() => {
                console.log("updated successfuly")
                dispatch({
                    type: "UPDATE_SUCCESS_BLOG"
                });
            })
            .catch(err => {
                console.log("updated fail")
                dispatch({
                    type: "UPDATE_BLOG_ERR",
                    err
                });
            });
    }
}
import React from 'react';

import {searchByBlog} from '../../redux/action/blogAction';
import { connect } from "react-redux";

import { compose } from "redux";
import { firestoreConnect } from "react-redux-firebase";
import RemoveBlog from './RemoveBlog';
import UpdateBlogBtn from './UpdateBlogBtn';
import timeAgo from "../../Shared/CalcMoment";


function FetchBlog(props) {
    // console.log(props)
    return (
        <div>
            {props.blogObj && props.blogObj[0] ? <div>
            <h3>Title Blog: {props.blogObj && props.blogObj[0].title}</h3> 
            <p>Last update: <i>{props.blogObj && timeAgo(props.blogObj[0].creationDate)}</i></p>

            <p dangerouslySetInnerHTML={{__html: props.blogObj[0].description}}></p>
            <hr></hr>

            <div className="card" style={{width: '90%', padding: '50px'}}>
                <div dangerouslySetInnerHTML={{__html: props.blogObj[0].blog}}></div>
                {/* <div className="card-body" style={{textAlign: 'left'}}> */}
                {/* <div dangerouslySetInnerHTML={{__html: props.blogObj[0].blog}}></div> */}

                {/* </div> */}
            <div>

            </div>
                      {/* <h3 style={{color: 'red'}}>الرجاء التآكد من الرابط</h3>  */}
            </div>                 {props.uid && props.blogObj && <RemoveBlog blogCase={props.blogObj[0]}/>}
                {props.uid && props.blogObj && <UpdateBlogBtn blogCase={props.blogObj[0]}/>}</div> : <p>Page not found</p>}
            
   

        </div>
    );
}


const mapDispatchToProps = state => {
    
    // console.log(state);
    const blogObj = state.firestore.ordered.blogs;
    return {
        blogObj: blogObj,
        uid: state.firebase.auth.uid
    };

}




export default compose(
    connect(mapDispatchToProps),
    firestoreConnect((ownProps) => 
    [
     {
        collection: "blogs",
        where: ["unique_title", "==", ownProps.title],
        // orderBy: ["date", "desc"],
      },
    ])

  ) (FetchBlog);
// import {toast} from "react-toastify";


const blogReducer = (state = {}, action) => {
    switch(action.type){
        case "ADD_BLOG":
            {
                // toast.success("Added task")
                // console.log("added task")
                return state
            }
        case "ADD_BLOG_ERR":
            {
                // toast.success("Added fail")
                // console.log("an erro occured with  task")
                return state
            }

        case "REMOVE_BLOG":
            {
                return state;
            }

        case "REMOVE_BLOG_ERR":
            {
                return state;
            }

        case "FETCH_SPECIFIC_BLOG":
            {
                return state;
            }
        
        case "FETCH_SPECIFIC_BLOG_ERR":
            {
                return state;
            }

        case "UPDATE_SUCCESS_BLOG":
            {
                return state;
            }
            
        case "UPDATE_BLOG_ERR":
            {
                return state;
            }

        default: return state
    }
};

export default blogReducer;
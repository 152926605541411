import React, {useState, useEffect, useLayoutEffect} from 'react';
import SoicalPanel from '../../Shared/SoicalPanel';
import { IoMdArrowRoundBack } from 'react-icons/io';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useParams
  } from "react-router-dom";


import { connect } from "react-redux";
import { compose } from "redux";
import { firestoreConnect } from "react-redux-firebase";

import {removeBlog} from "../../redux/action/blogAction"
import FetchBlog from './FetchBlog';

const SubBlog = (props) => {
    
  const {id} = props.match.params;
  // const {blog} = props.location.state;
  // console.log(props.match.params.id)

  const handleRemoveBlog = (blog) => {
      // console.log("hi", blog)
      props.removeBlog(blog)
    }

    return (
        <div className="content" id ="content">
          <h1>{props.match.params.id}</h1>
          <div className="panel-center">
              <Link to="/blogs"><h1><IoMdArrowRoundBack /></h1></Link>
              
                    <FetchBlog title={props.match.params.id}/>
            </div>

                  <div className="panel-right">
                      {/* <button onClick={() => handleRemoveBlog(blog)}>Remove blog</button> */}
                    </div>
                    {/* <SoicalPanel/> */}
              </div>
        
    )
}

const mapDispatchToProps = dispatch => {
  
    return {
      removeBlog: blog => dispatch(removeBlog(blog))
    }
  }


export default connect(null, mapDispatchToProps) (SubBlog);